import axios from 'axios';
const apiURL = process.env.GATSBY_API_URL;
import { useDispatch } from 'react-redux';

const useAuth = () => {
  const dispatch = useDispatch();

  const login = credentials =>
    new Promise(async (resolve, reject) => {
      try {
        const { data: payload } = await axios.post(`${apiURL}/auth/local`, credentials);
        dispatch({ type: 'LOGIN', payload });
        resolve(payload);
      } catch (e) {
        reject(e);
      }
    });

  const register = (userData) => new Promise(async (resolve, reject) => {
    try {
      const { data: payload } = await axios.post(`${apiURL}/auth/local/register`, userData);
      dispatch({ type: 'LOGIN', payload });
      resolve(payload);
    } catch (e) {
      reject(e);
    }
  });

  const logout = () => {
    dispatch({ type: 'LOGOUT' });
  };

  return { login, logout, register };
};

export default useAuth;
