import React, { useState } from 'react';
import '../styles/register.scss';
import { Col, Row, Button, Input, Checkbox, Typography, Form, Alert } from 'antd';
import { CameraOutlined } from '@ant-design/icons';
import useAuth from '../api/auth';
import { Layout } from 'antd';
const { Content, } = Layout;
import { navigate } from 'gatsby';
import HeaderComponent from '../components/header/header';

const { Text } = Typography;

const msg2str = msgs => {
  return msgs.map(e => e.messages.map(f => f.message)).join('');
};

const RegisterPage = () => {
  const [form] = Form.useForm();
  const { register } = useAuth();

  const [loading, setLoading] = useState(false);
  const [registerErrorMsg, setRegisterErrorMsg] = useState();

  const showErrorMsg = msg => {
    setRegisterErrorMsg(msg);
    setTimeout(() => {
      setRegisterErrorMsg();
    }, 5000);
  };

  const onFinish = async values => {
    try {
      const redone = { ...values, username: values.email };
      setLoading(true);
      await register(redone);
      setLoading(false);
      navigate('/home');
    } catch (e) {
      const {
        response: {
          data: { data },
        },
      } = e;
      setLoading(false);
      showErrorMsg('Error registrando datos: ' + msg2str(data));
    }
  };

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
    // showErrorMsg('Error registrando datos');
  };

  return (
    <Layout className="site-layout-background">
      <HeaderComponent title="Registrarse" goTo="/" />
      <Content>
        <div className="content-register">
          <Form
            layout="vertical"
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            initialValues={{ agree: false }}>
            <Form.Item
              name="fullname"
              rules={[{ required: true, message: 'por favor ingrese su username' }]}>
              <Input
                className="sty-input"
                placeholder="Ingrese su nombre completo"
                suffix="Nombre"
              />
            </Form.Item>
            <Form.Item
              name="email"
              rules={[{ required: true, message: 'Debe ingresar un email válido' }]}>
              <Input className="sty-input" placeholder="Ingrese su email" suffix="E-mail" />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[{ required: true, message: 'Por favor ingrese una clave' }]}>
              <Input.Password className="sty-input" placeholder="Password" />
            </Form.Item>
            <Form.Item className="color-row">
              <Row justify="space-around" align="middle" gutter={10}>
                <Col span={8} offset={2}>
                  <CameraOutlined style={{ fontSize: 28 }} className="icon-color-primary"/>
                </Col>
                <Col span={12}>
                  <Text className="font-sty">
                    Escanea tu Cédula de Identidad Pasaporte, Licencia de conducir
                  </Text>
                </Col>
              </Row>
            </Form.Item>
            <Form.Item
              name="agree"
              valuePropName="checked"
              rules={[
                {
                  validator: (_, value) =>
                    value ? Promise.resolve() : Promise.reject('Aceptar condiciones y términos'),
                },
              ]}>
              <Checkbox>Estoy desacuerdo con los Términos y Condiciones</Checkbox>
            </Form.Item>
            <Form.Item>
              {!!registerErrorMsg && (
                <Alert message={registerErrorMsg} type="error" closable banner showIcon />
              )}
            </Form.Item>
            <Form.Item>
              <Button size="large" htmlType="submit" className="btn-sty" type="primary" block loading={loading}>
                REGISTRARSE
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Content>
    </Layout>
  );
};

export default RegisterPage;
